import axios from "axios";

const baseURL = `${process.env.REACT_APP_API_URL}/`;
const docServerURL = `${process.env.REACT_APP_DOC_SERVER_URL}/`;

export const getAxios = () => {
  let axiosInstance;

  const headers = {};

  axiosInstance = axios.create({
    headers,
    timeout: 30000,
  });

  axiosInstance.interceptors.response.use(
    function (response) {
      return Promise.resolve(response);
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const get = (uri, config) => {
  return new Promise((resolve, reject) => {
    getAxios()
      .get(baseURL + uri, config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const post = (uri, data, config) => {
  return new Promise((resolve, reject) => {
    getAxios()
      .post(baseURL + uri, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postDoc = (uri, data, config) => {
  return new Promise((resolve, reject) => {
    getAxios()
      .post(docServerURL + uri, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const put = (uri, data, config) => {
  return new Promise((resolve, reject) => {
    getAxios()
      .put(baseURL + uri, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patch = (uri, data, config) => {
  return new Promise((resolve, reject) => {
    getAxios()
      .patch(baseURL + uri, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteAPI = (uri, config) => {
  return new Promise((resolve, reject) => {
    getAxios()
      .delete(baseURL + uri, config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
