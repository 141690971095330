import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import { Button, Select, Input as AntInput, message, Popconfirm } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import { SOURCE_ONBOARDING } from "../../../assets/const/form";
import {
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_AUTO,
  COVERAGE_KEY_WORKERS_COMP,
  COVERAGE_KEY_UMBRELLA,
  COVERAGE_KEY_ADDITIONAL_INFO,
  COVERAGE_KEY_CRIME,
  COVERAGE_KEY_GARAGE,
  COVERAGE_KEY_EPLI,
  COVERAGE_KEY_CYBER,
  COVERAGE_KEY_PROFESSIONAL,
  COVERAGE_KEY_POLLUTION,
  COVERAGE_KEY_INLAND_MARINE,
  COVERAGE_KEY_PRO_INS,
  COVERAGE_KEY_EXEMPTION,
  COVERAGE_OPERATOR_AND,
  COVERAGE_OPERATOR_OR
} from "@assets/const/status";
import { useAppState } from "@context";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined
} from "@ant-design/icons";

const COVERAGE_OPERATOR_LIST = [
  {
    value: COVERAGE_OPERATOR_AND,
    label: COVERAGE_OPERATOR_AND
  },
  {
    value: COVERAGE_OPERATOR_OR,
    label: COVERAGE_OPERATOR_OR
  }
];

const COVERAGE_OPTIONS = [
  { label: "Liab", value: COVERAGE_KEY_LIABILITY },
  { label: "Auto", value: COVERAGE_KEY_AUTO },
  { label: "WC", value: COVERAGE_KEY_WORKERS_COMP },
  { label: "Umb", value: COVERAGE_KEY_UMBRELLA },
  { label: "Crime", value: COVERAGE_KEY_CRIME },
  {
    label: "Garage",
    value: COVERAGE_KEY_GARAGE
  },
  {
    label: "EPLI",
    value: COVERAGE_KEY_EPLI
  },
  {
    label: "Cyber",
    value: COVERAGE_KEY_CYBER
  },
  {
    label: "Professional",
    value: COVERAGE_KEY_PROFESSIONAL
  },
  {
    label: "Pollution",
    value: COVERAGE_KEY_POLLUTION
  },
  {
    label: "Inland Marine",
    value: COVERAGE_KEY_INLAND_MARINE
  },
  {
    label: "Property Insurance",
    value: COVERAGE_KEY_PRO_INS
  },
  { label: "Cert", value: COVERAGE_KEY_ADDITIONAL_INFO }
];

const COVERAGE_TYPE_OPTIONS = [
  {
    label: "Business Entity",
    value: "insuranceData",
    subOptions: [
      {
        label: "Entity Name",
        value: "entity_name"
      },
      {
        label: "Entity Type",
        value: "entity_type"
      },
      {
        label: "DBA",
        value: "dba"
      },
      {
        label: "Opening Date",
        value: "opening_date"
      },
      {
        label: "Tax ID",
        value: "tax_id"
      },
      {
        label: "Center Name/Location Number",
        value: "centerName"
      },
      {
        label: "Estimated Annual Revenue",
        value: "annualRevenue"
      }
    ]
  },
  {
    label: "Location List",
    value: "commonLocList",
    subOptions: [
      {
        label: "Address",
        value: "address"
      },
      {
        label: "City",
        value: "city"
      },
      {
        label: "State",
        value: "state"
      },
      {
        label: "Zip",
        value: "zip"
      }
    ]
  },
  {
    label: "Point of Contact",
    value: "poc_contacts",
    subOptions: [
      {
        label: "First Name",
        value: "firstName"
      },
      {
        label: "Last Name",
        value: "lastName"
      },
      {
        label: "Email",
        value: "email"
      },
      {
        label: "Telephone",
        value: "telephone"
      },
      {
        label: "Direct Line",
        value: "directLine"
      },
      {
        label: "Preferred Method",
        value: "preferredMethod"
      }
    ]
  },
  {
    label: "Property Info",
    value: "propertyList",
    subOptions: [
      {
        label: "Total Square Feet",
        value: "totalArea"
      },
      {
        label: "Year Built",
        value: "yearBuilt"
      },
      {
        label: "Construction Type Code",
        value: "constructionTypeCode"
      },
      {
        label: "Number of Stories",
        value: "numberOfStories"
      },
      {
        label: "Equipment, Stock, Inventory, Furniture Value",
        value: "bppLimit"
      },
      {
        label: "Tenant Improvements",
        value: "tenantImprovement"
      }
    ]
  },
  {
    label: "Payroll",
    value: "payrollList",
    subOptions: [
      {
        label: "Class Code",
        value: "class_code"
      },
      {
        label: "Annual Payroll",
        value: "annual_payroll"
      },
      {
        label: "Full-Time Employee",
        value: "full_time_emp"
      },
      {
        label: "Part-Time Employee",
        value: "part_time_emp"
      }
    ]
  },
  {
    label: "Subcontractor Expenses",
    value: "subcontractorList",
    subOptions: [
      {
        label: "Class Code",
        value: "class_code"
      },
      {
        label: "Uninsured Subcontractor Expenses",
        value: "expense"
      }
    ]
  },
  {
    label: "Owners & Officers",
    value: "officeList",
    subOptions: [
      {
        label: "Full Name",
        value: "full_name"
      },
      {
        label: "Excluded/Included",
        value: "name_type"
      },
      {
        label: "Payroll",
        value: "payroll"
      },
      {
        label: "Class Code",
        value: "class_code"
      },
      {
        label: "Ownership",
        value: "owner_ship"
      }
    ]
  },
  {
    label: "Auto",
    value: "vehicleList",
    subOptions: [
      {
        label: "Year",
        value: "year"
      },
      {
        label: "Make",
        value: "make"
      },
      {
        label: "Model",
        value: "model"
      },
      {
        label: "Vehicle Value",
        value: "value"
      },
      {
        label: "Attached Equipment Value",
        value: "equipment"
      },
      {
        label: "VIN",
        value: "vin"
      },
      {
        label: "Garage Location",
        value: "garageAddress"
      },
      {
        label: "City",
        value: "garageCity"
      },
      {
        label: "State",
        value: "garageState"
      },
      {
        label: "Zip",
        value: "garageZip"
      },

      {
        label: "Parked at Home",
        value: "parkHome"
      },

      {
        label: "Tracking device installed",
        value: "trackDevice"
      }
    ]
  },
  {
    label: "Drivers",
    value: "driverList",
    subOptions: [
      {
        label: "First Name",
        value: "firstName"
      },
      {
        label: "Last Name",
        value: "lastName"
      },
      {
        label: "Driver's License",
        value: "driversLicenseNumber"
      },
      {
        label: "State of License",
        value: "stateOfLicense"
      },
      {
        label: "Date of Birth",
        value: "dateOfBirth"
      }
    ]
  },
  {
    label: "Build-Out Info",
    value: "buildOutInfo",
    subOptions: [
      {
        label: "Year Built",
        value: "yearBuilt"
      },
      {
        label: "Square Feet",
        value: "squareFeet"
      },
      {
        label: "Num of Stories",
        value: "numOfStories"
      },
      {
        label: "Total Build-Out Cost",
        value: "buildOutCost"
      },
      {
        label: "Construction Type",
        value: "constructionType"
      },
      {
        label: "Description of Security",
        value: "descriptionOfSecurity"
      },
      {
        label: "Start Date",
        value: "constructionStateDate"
      },
      {
        label: "Estimated End Date",
        value: "constructionEstimatedEndDate"
      },
      {
        label: "Business Name",
        value: "contractorBusinessName"
      },
      {
        label: "Mailing Address",
        value: "contractorMailingAddress"
      },
      {
        label: "Bank Name",
        value: "bankName"
      },
      {
        label: "Bank Mailing Address",
        value: "bankMailingAddress"
      },
      {
        label: "Address",
        value: "address"
      },
      {
        label: "Line 2",
        value: "address2"
      },
      {
        label: "City",
        value: "city"
      },
      {
        label: "State",
        value: "state"
      },
      {
        label: "Zip",
        value: "zip"
      },
      {
        label: "Country",
        value: "country"
      }
    ]
  },
  {
    label: "Scheduled Equipment",
    value: "equipmentList",
    subOptions: [
      {
        label: "Description",
        value: "desc"
      },
      {
        label: "Year",
        value: "year"
      },
      {
        label: "Make",
        value: "make"
      },
      {
        label: "Model",
        value: "model"
      },
      {
        label: "Serial Number",
        value: "serialNumber"
      },
      {
        label: "Value",
        value: "value"
      }
    ]
  },
  {
    label: "Unscheduled Equipment",
    value: "unScheEquipList",
    subOptions: [
      {
        label: "Equipment Description or Label",
        value: "desc"
      },
      {
        label: "Unschedule Equipment Value",
        value: "value"
      }
    ]
  }
];

const OPERATOR_OPTIONS = [
  {
    label: ">",
    value: ">"
  },
  {
    label: "=",
    value: "="
  },
  {
    label: "<",
    value: "<"
  },
  {
    label: "≠",
    value: "≠"
  },
  {
    label: "≤",
    value: "≤"
  },
  {
    label: "≥",
    value: "≥"
  }
];

export default function ExemptionForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [coverageKey, setCoverageKey] = useState(
    Object.keys(sourceData?.exemption || {})
  );

  function getCoverageTitle(key) {
    switch (key) {
      case COVERAGE_KEY_LIABILITY:
        return "Liability";
      case COVERAGE_KEY_AUTO:
        return "Auto";
      case COVERAGE_KEY_WORKERS_COMP:
        return "Workers Compensation";
      case COVERAGE_KEY_UMBRELLA:
        return "Umbrella";
      case COVERAGE_KEY_CRIME:
        return "Crime";
      case COVERAGE_KEY_GARAGE:
        return "Garage";
      case COVERAGE_KEY_EPLI:
        return "Employment Practices Liability";
      case COVERAGE_KEY_CYBER:
        return "Cyber";
      case COVERAGE_KEY_PROFESSIONAL:
        return "Professional Liability";
      case COVERAGE_KEY_POLLUTION:
        return "Pollution Liability";
      case COVERAGE_KEY_INLAND_MARINE:
        return "Inland Marine";
      case COVERAGE_KEY_PRO_INS:
        return "Property Insurance";
      case COVERAGE_KEY_ADDITIONAL_INFO:
        return "Cert";
    }
  }

  const [exemForm, setExemForm] = useState(sourceData?.exemption || {});

  useEffect(() => {
    setExemForm(sourceData?.exemption || {});
    setCoverageKey(Object.keys(sourceData?.exemption || {}));
  }, [sourceData]);

  function handleAddSubForm(key) {
    if (exemForm[key]?.data) {
      exemForm[key].data.push({
        coverage: "",
        dataType: "",
        operator: "",
        parameters: ""
      });
    } else {
      exemForm[key] = {
        operator: COVERAGE_OPERATOR_AND,
        data: [
          {
            coverage: "",
            dataType: "",
            operator: "",
            parameters: ""
          }
        ]
      };
    }
    setExemForm({ ...exemForm });
  }

  function handleChangeOperator(key, operator) {
    exemForm[key].operator = operator;

    setExemForm({ ...exemForm });
  }

  function handleRemoveSub(key, conIndex) {
    exemForm[key]?.data?.splice(conIndex, 1);
    setExemForm({ ...exemForm });
  }

  function handleEdit() {
    setIsEdit(true);
  }

  function getValueFromOption(optionList, value) {
    const item = optionList.find((item) => item.value === value);
    return item?.label;
  }

  async function handleSave() {
    const { onUpdate } = props;
    setLoading(true);
    const param = {};
    coverageKey.forEach((itemKey) => {
      param[itemKey] = exemForm[itemKey];
    });

    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { exemption: param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { exemption: param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
      message.success("Exemption info saved successfully!");
    } catch (e) {
      setLoading(false);
    }
  }

  function handleCancel() {
    setIsEdit(false);
  }

  function handlSubFormUpdate(coverageKey, fieldKey, index, value) {
    exemForm[coverageKey].data[index][fieldKey] = value;
    if (fieldKey === "coverage") {
      const coverageTypeOption = COVERAGE_TYPE_OPTIONS.find(
        (item) => item.value === value
      );
      exemForm[coverageKey].data[index]["options"] =
        coverageTypeOption?.subOptions || [];
    }
    setExemForm({ ...exemForm });
  }

  return (
    <div className="exem">
      <div className="exem_header">
        <div className="exem_header-action">
          {!isEdit ? (
            <Popconfirm title="Are you sure to edit?" onConfirm={handleEdit}>
              <Button icon={<EditOutlined />} type="primary">
                Edit
              </Button>
            </Popconfirm>
          ) : (
            <>
              <Button
                loading={loading}
                icon={<SaveOutlined />}
                type="primary"
                onClick={handleSave}
              >
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          )}
        </div>
        <label>
          <b>Add Coverage:</b>
        </label>
        <Select
          disabled={!isEdit}
          size="large"
          mode="multiple"
          value={coverageKey}
          style={{ minWidth: "250px" }}
          options={COVERAGE_OPTIONS}
          onChange={setCoverageKey}
        ></Select>
      </div>
      <div className="exem_content">
        {coverageKey.map((itemKey) => (
          <div className="exem_content-item">
            <div className="exem_content-item-wraper">
              <div className="exem_content-item-wraper-header">
                <h3>
                  <b>{getCoverageTitle(itemKey)}</b>
                  {isEdit && (
                    <Button
                      style={{ marginLeft: "10px" }}
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => handleAddSubForm(itemKey)}
                    ></Button>
                  )}
                </h3>

                {isEdit ? (
                  <div>
                    Operator:{" "}
                    <Select
                      style={{ width: "100px" }}
                      options={COVERAGE_OPERATOR_LIST}
                      onChange={(val) => {
                        handleChangeOperator(itemKey, val);
                      }}
                    />
                  </div>
                ) : (
                  <div>Operator: {exemForm[itemKey]?.operator}</div>
                )}
              </div>

              <div className="exem_content-item-wraper-action"></div>

              {(exemForm[itemKey]?.data || []).map((item, conIndex) => (
                <>
                  {isEdit ? (
                    <div className="exem_content-item-wraper-item">
                      <div>
                        <div>
                          <b>Coverage:</b>
                        </div>
                        <Select
                          value={item.coverage}
                          size="large"
                          style={{ width: "200px" }}
                          options={COVERAGE_TYPE_OPTIONS}
                          onChange={(value) =>
                            handlSubFormUpdate(
                              itemKey,
                              "coverage",
                              conIndex,
                              value
                            )
                          }
                        />
                      </div>
                      <div>
                        <div>
                          <b>Data Type:</b>
                        </div>
                        <Select
                          style={{ width: "200px" }}
                          size="large"
                          options={item.options}
                          value={item.dataType}
                          onChange={(value) =>
                            handlSubFormUpdate(
                              itemKey,
                              "dataType",
                              conIndex,
                              value
                            )
                          }
                        />
                      </div>
                      <div>
                        <div>
                          <b>Operator:</b>
                        </div>
                        <Select
                          size="large"
                          style={{ width: "200px" }}
                          options={OPERATOR_OPTIONS}
                          value={item.operator}
                          onChange={(value) =>
                            handlSubFormUpdate(
                              itemKey,
                              "operator",
                              conIndex,
                              value
                            )
                          }
                        />
                      </div>
                      <div>
                        <div>
                          <b>Parameters:</b>
                        </div>
                        <AntInput
                          value={item.parameters}
                          onChange={(evt) =>
                            handlSubFormUpdate(
                              itemKey,
                              "parameters",
                              conIndex,
                              evt.target.value
                            )
                          }
                        />
                      </div>
                      <div>
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveSub(itemKey, conIndex)}
                        ></Button>
                      </div>
                    </div>
                  ) : (
                    <div className="exem_content-item-wraper-con">
                      <div>
                        <b>Coverage: </b>
                        {getValueFromOption(
                          COVERAGE_TYPE_OPTIONS,
                          item.coverage
                        )}
                      </div>

                      <div>
                        <b>Data Type: </b>
                        {getValueFromOption(item.options, item.dataType)}
                      </div>

                      <div>
                        <b>Operator: </b>
                        {getValueFromOption(OPERATOR_OPTIONS, item.operator)}
                      </div>

                      <div>
                        <b>Parameters: </b>
                        {item.parameters}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
