import React, { useState } from "react";
import { Button, DatePicker, Form, Input } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { getHostTag } from "@utils/host";
import { post } from "@utils/axios";
import "./style.scss";
import { differenceInSeconds } from "date-fns";

export default function APIKeyForm({ onRefresh, onClose }) {
  const [keyForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  async function handleSubmit(values) {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `api-keys`,
        {
          name: values.name,
          host: getHostTag(),
          expireAt: values?.expireAt ? values.expireAt.format() : null
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setLoading(false);
      onRefresh();
      onClose();
    } catch (err) {
      setLoading(false);
    }
  }

  function onCancel() {
    keyForm.resetFields();
    onClose();
  }

  return (
    <div className="api_key">
      <Form layout="vertical" form={keyForm} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input your name." }]}
        >
          <Input title="Name" type="text" placeholder="Name" />
        </Form.Item>
        <Form.Item name="expireAt" label="Expire Date">
          <DatePicker size="large" style={{ width: "100%" }} />
        </Form.Item>

        <div className="api_key-footer">
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create
          </Button>
        </div>
      </Form>
    </div>
  );
}
