import React, { useState, useEffect, useMemo } from "react";
import { Table, notification, Button, Modal, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading, Card } from "@components";
import { useAppState } from "@context";
import { PERMISSION_TABLE, ACCESS_READ_ONLY } from "@utils/permission";
import "./style.scss";
import { deleteAPI, get } from "@utils/axios";
import APIKeyForm from "./api-key-form";
import { Redirect } from "react-router-dom";
import { getHostTag } from "@utils/host";
import { format } from "date-fns";

const MODAL_TYPE_FORM = "MODAL_TYPE_FORM";
const MODAL_TYPE_NONE = "MODAL_TYPE_NONE";

export default function AdminApiKeys(props) {
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [keyList, setKeyList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalType, setModalType] = useState(MODAL_TYPE_NONE);
  const { authSetting } = useAppState();
  const { getAccessTokenSilently } = useAuth0();

  const dataSource = useMemo(() => {
    return keyList?.map((item) => ({
      ...item,
      key: item._id
    }));
  }, [keyList]);

  useEffect(() => {
    handleFetchAPIKeys();
  }, []);

  if (
    PERMISSION_TABLE["USER_MANAGEMENT"][authSetting.userPermission] <
    ACCESS_READ_ONLY
  ) {
    return <Redirect to="/admin/overview" />;
  }

  async function handleFetchAPIKeys() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const host = getHostTag();
      const result = await get(`api-keys?host=${host ? host : ""}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        setKeyList(result.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleNotification(
        "Failed!",
        "Keys loading is failed. Please contact us."
      );
    }
  }

  async function handleRemove(record) {
    setIsOpen(false);
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await deleteAPI(`api-keys/${record._id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    handleFetchAPIKeys();
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  const rowSelection = {
    selectedRowKeys: checkedList.map((item) => item.key),
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRows);
    }
  };

  function handleAddKey() {
    setModalTitle("Register Key");
    setModalType(MODAL_TYPE_FORM);
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
    setModalTitle("");
    setModalType(MODAL_TYPE_NONE);
  }

  function renderModal() {
    return (
      <Modal
        className={
          modalType === MODAL_TYPE_FORM
            ? "custom-modal_form"
            : "custom-modal_delete"
        }
        title={modalTitle}
        open={isOpen}
        footer={null}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
      >
        {modalType === MODAL_TYPE_FORM && (
          <APIKeyForm
            onClose={handleCloseModal}
            onRefresh={handleFetchAPIKeys}
          />
        )}
      </Modal>
    );
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Access Key",
      dataIndex: "accessKey",
      key: "accessKey",
      render: (text) => <p className="overflow-wrap-any">{text}</p>
    },
    {
      title: "Expire At",
      dataIndex: "expireAt",
      key: "expireAt",
      render: (text) => (
        <div>{text ? format(new Date(text), "PPP, HH:mm") : "-"}</div>
      )
    },
    {
      title: "Host",
      dataIndex: "host",
      key: "host"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          {PERMISSION_TABLE["FRANCHISOR_DELETE"][authSetting.userPermission] >
            0 && (
            <Popconfirm
              title="Delete this API Key?"
              description="Are you sure to delete this API Key?"
              onConfirm={() => handleRemove(record)}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ];

  return (
    <div className="admin-users-container">
      <div className="action-btn-container">
        <div className="admin-users-container_toolbar">
          {PERMISSION_TABLE["FRANCHISOR_ADD"][authSetting.userPermission] >
            0 && (
            <Button onClick={handleAddKey} type="primary">
              <PlusOutlined />
            </Button>
          )}
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
      />
      <Loading loading={loading} />
      {renderModal()}
    </div>
  );
}
