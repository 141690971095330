import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { Button, Modal, Select, Table, Space, message, Input } from "antd";
import { LinkOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { formatToMonthDayYear } from "@utils/date-formatter";
import { get, post, put, deleteAPI } from "@utils/axios";

export default function Proposal({ ledgerData = [], requirementId }) {
  const { getAccessTokenSilently } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [proposals, setProposals] = useState([]); // Stores all proposals
  const [selectedLedgerIds, setSelectedLedgerIds] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (requirementId) {
      fetchProposals(requirementId);
    }
  }, [requirementId]);

  // ✅ Fetch proposals
  const fetchProposals = async (requirementId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await get(
        `quote-proposal/get-proposals/${requirementId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setProposals(response.data);
    } catch (error) {
      message.error("Error fetching proposals");
      console.error("Fetch proposals failed:", error);
    }
  };

  // ✅ Open modal
  const handleOpenModal = () => setIsModalOpen(true);

  // ✅ Close modal & reset selection
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLedgerIds([]);
  };

  // ✅ Accept selected ledgers into a proposal
  const handleAcceptProposal = async () => {
    const selectedLedgers = ledgerData.filter((ledger) =>
      selectedLedgerIds.includes(ledger._id)
    );

    if (selectedLedgers.length === 0) {
      message.warning("Please select at least one ledger.");
      return;
    }

    const proposalDetails = selectedLedgers.map((ledger) => ({
      coverage: Array.isArray(ledger.coverage) ? ledger.coverage.join(", ") : ledger.coverage || "N/A",
      effective: formatToMonthDayYear(ledger.effective),
      agent: ledger.agent,
      carrier: ledger.carrier,
    }));

    try {
      const token = await getAccessTokenSilently();
      const response = await post(
        "quote-proposal/create-proposal",
        { requirementId, detail: proposalDetails, name: "New Proposal" },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 201) {
        message.success("Proposal created successfully");
        fetchProposals(requirementId);
      }
    } catch (error) {
      message.error("Error creating proposal");
      console.error("Create proposal failed:", error);
    }

    setSelectedLedgerIds([]);
    setIsModalOpen(false);
  };

  // ✅ Delete a proposal
  const handleDeleteProposal = async (_id) => {
    try {
      const token = await getAccessTokenSilently();
      await deleteAPI(`quote-proposal/delete-proposal/${_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      message.success("Proposal deleted successfully");
      fetchProposals(requirementId);
    } catch (error) {
      message.error("Error deleting proposal");
      console.error("Delete proposal failed:", error);
    }
  };

  // ✅ Update proposal name
  const handleEditProposal = async (_id, name) => {
    try {
      const token = await getAccessTokenSilently();
      await put(
        `quote-proposal/update-proposal/${_id}`,
        { name },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      message.success("Proposal updated successfully");
      fetchProposals(requirementId);
    } catch (error) {
      message.error("Error updating proposal");
      console.error("Update proposal failed:", error);
    }
  };

  const handleInputChange = (_id, field, value) => {
    setProposals((prevData) =>
      prevData.map((item) => (item._id === _id ? { ...item, [field]: value } : item))
    );
  };

  const columns = [
    {
      title: "Proposal Details",
      dataIndex: "detail",
      key: "proposalDetails",
      render: (detail, record) => (
        <div>
          <strong>{record.name}</strong> <br /> {/* Proposal Name */}
          Coverage: <strong>{detail.map((entry) => entry.coverage).join(", ")}</strong> <br />
          Effective Date: <strong>{detail.map((entry) => entry.effective).join(", ")}</strong> <br />
          Agent: <strong>{detail.map((entry) => entry.agent).join(", ")}</strong> <br />
          Carrier: <strong>{detail.map((entry) => entry.carrier).join(", ")}</strong>
        </div>
      ),
    },
    {
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button type="link" icon={<EditOutlined />} onClick={() => setIsEditing(true)}>
            Edit
          </Button>
          <Button type="link" icon={<LinkOutlined />}>
            Copy Link
          </Button>
          <Button type="text" danger icon={<DeleteOutlined />} onClick={() => handleDeleteProposal(record._id)} />
        </Space>
      ),
    },
  ];


  return ledgerData.length === 0 ? (
    <div>
      <pre style={{ color: "#8c8c8c", marginTop: "40px", fontSize: "14px", border: "1px dashed #d9d9d9", padding: "16px" }}>
        No proposals available. Please create a quote (ledger) first.
      </pre>
    </div>
  ) : (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Button icon={<PlusOutlined />} onClick={handleOpenModal} loading={loading}>
          Add Proposal
        </Button>
        {/* <Button style={{ float: "right" }} onClick={() => setIsEditing(!isEditing)} loading={loading}>
          {isEditing ? "Save" : <EditOutlined />}
        </Button> */}
      </Space>

      <Table columns={columns} dataSource={proposals} pagination={false} bordered />

      {/* Modal for Selecting Ledgers */}
      <Modal
        title="Select Ledgers for Proposal"
        open={isModalOpen}
        onCancel={handleCloseModal}
        onOk={handleAcceptProposal}
        okText="Accept"
      >
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select Ledgers"
          value={selectedLedgerIds}
          onChange={setSelectedLedgerIds}
        >
          {ledgerData.map((ledger) => {
            const coverageText = Array.isArray(ledger.coverage) ? ledger.coverage.join(", ") : ledger.coverage || "N/A";

            return (
              <Select.Option key={ledger._id} value={ledger._id}>
                {coverageText} - {formatToMonthDayYear(ledger.effective)}
              </Select.Option>
            );
          })}
        </Select>
      </Modal>
    </div>
  );
}
