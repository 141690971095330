import React, { useState, useEffect } from 'react';
import { Select, Input, Switch, Button, DatePicker } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { get } from '@utils/axios';
import './style.scss'

import Ledger from './ledger'
import Proposal from './proposal';

const { Option } = Select;

const DROPDOWN_FIELDS = ["carrier", "carrier type", "bill type", "status"];

// example ledgerData
//   key: "1",
//   name: "Liability-2022-01-01-$9000-Hartford",
//   coverage: "Liability",
//   effective: "2022-01-01",
//   expiration: "2022-01-01",
//   premium: "9000",
//   user: "Jon@rikor.io",
//   carrier: "Hartford",
//   status: "Not in Market",

export default function QuoteProposal({ franchisorData }) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedPolicies, setSelectedPolicies] = useState({});
  const [selectedQuote, setSelectedQuote] = useState([]);
  const [backendData, setBackendData] = useState({});
  const [ledgerData, setLedgerData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await get('admin-board/all-tables');
      const organizedData = result.data.reduce((acc, item) => {
        acc[item.metaType] = acc[item.metaType] || [];
        acc[item.metaType].push(item);
        return acc;
      }, {});
      setBackendData(organizedData);
    }
    fetchData();
  }, []);

  console.log('backendData', backendData);

  const handleSelectPolicy = (index, values) => {
    const combinedData = values.flatMap(value => backendData[value] || []);
    setSelectedPolicies((prev) => ({ ...prev, [index]: combinedData }));
  };

  const renderCards = () => {
    return (
      <div style={{ marginTop: '20px' }}>
        {selectedQuote.map((quote, cardIndex) => (
          <div key={cardIndex} className="card-container custom-card-container">
            <div className="custom-dropdown-row">
              {/* Read-only Input to Display Coverage */}
              <Input
                className="custom-readonly-select"
                style={{
                  flex: 1,
                  height: '32px', // Match dropdown height
                  padding: '4px 11px', // Same padding as Select
                  border: '1px solid #d9d9d9', // Match border color
                  borderRadius: '6px', // Rounded corners like Select
                  backgroundColor: '#ffffff', // Match dropdown background
                  cursor: 'default',
                  color: 'rgba(0, 0, 0, 0.85)', // Match text color
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content'
                }}
                value={quote.coverage ? quote.coverage.join(', ') : 'No Coverage'}
                readOnly
              />

              {
                Object.keys(backendData)
                  .filter((key) => DROPDOWN_FIELDS.includes(key.toLowerCase())) // Only render known dropdowns
                  .map((key) => {
                    const table = backendData[key].find(
                      (table) => table.displayLabel.toLowerCase() === key.toLowerCase()
                    );

                    return (
                      table && (
                        <Select
                          key={key}
                          placeholder={`Select ${table.displayLabel}`}
                          className="custom-select"
                        >
                          {table.fields?.map((field) => (
                            <Option key={field._id} value={field._id}>
                              {field.displayLabel}
                            </Option>
                          ))}
                        </Select>
                      )
                    );
                  })
              }

              {/* Agent Field - Static Input */}
              <Input value={user?.nickname} className="custom-readonly-select" readOnly />

              {/* {staticDropdownData.map((item, idx) => (
                <div key={idx} className="custom-dropdown-row">
                  {item === 'Agent' ? (
                    <Input value={user?.nickname} className="custom-readonly-select" readOnly />
                  ) : (
                    <Select placeholder={item} className="custom-select">
                      <Option value={`${item} 1`}>{`${item} 1`}</Option>
                      <Option value={`${item} 2`}>{`${item} 2`}</Option>
                      <Option value={`${item} 3`}>{`${item} 3`}</Option>
                    </Select>
                  )}
                </div>
              ))} */}
            </div>

            {selectedPolicies[cardIndex] && selectedPolicies[cardIndex].length > 0 && (
              <div className="custom-single-card" style={{ width: '100%', borderRadius: '8px' }}>
                <div className="custom-card-header">
                  <DatePicker placeholder="Effective" className="custom-input" style={{ height: "5vh" }} />
                  <DatePicker placeholder="Expiration" className="custom-input" style={{ height: "5vh" }} />
                  <Input placeholder="Policy #" className="custom-input" style={{ height: "5vh" }} />
                  <Input placeholder="Premium + Tax" className="custom-input" style={{ height: "5vh" }} />
                  {/* <div className="entry-type">Manual</div> */}
                </div>
                {selectedPolicies[cardIndex].map((policy) => (
                  <div key={policy._id}>
                    <h4 className="custom-card-title">{policy.displayLabel}</h4>
                    {policy.fields.map((field) => (
                      <div key={field._id} className="custom-field-row no-border" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <span>{field.displayLabel}</span>
                        {field.fieldType === 'number' ? (
                          <Input type="number" className="custom-input" style={{ maxWidth: '10vw' }} />
                        ) : field.fieldType === 'boolean' ? (
                          <Switch className="custom-switch" />
                        ) : field.fieldType === 'date' ? (
                          <DatePicker className='custom-input' style={{ maxWidth: '10vw'}} />
                        ) : (
                          <Input className="custom-input" style={{ maxWidth: '10vw' }} />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };


  return (
    <div className="custom-quote-proposal-container" >
      <div className='ledger-proposal-container'>
        <Ledger
          ledgerData={ledgerData}
          setLedgerData={setLedgerData}
          requirementId={franchisorData?._id}
          selectedQuote={selectedQuote}
          setSelectedQuote={setSelectedQuote}
          handleSelectPolicy={handleSelectPolicy}
          backendData={backendData}
        />

        <Proposal
          ledgerData={ledgerData}
          requirementId={franchisorData?._id}
        />
      </div>
      {ledgerData.length ? renderCards() :  <div>
      <pre style={{ color: "#8c8c8c", fontSize: "14px", border: "1px dashed #d9d9d9", padding: "16px" }}>
        No Quote Data available. Please create a quote first.
      </pre>
    </div>}
    </div>
  );
}
