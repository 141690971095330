import "./style.scss";
import { Card } from "@components";
import { Tabs } from "antd";
import { useAppState } from "@context";
import PersonalInfor from "./personal-infor";
import InsuranceBundle from "./insurance-bundle";
import ZeeList from "./zee-list";
import InsuranceRequirements from "./insurance-requirements";
import Integrations from "./integrations";
import RealTimeNotifications from "./real-time-notifications";
import { PERMISSION_TABLE, ACCESS_FULL } from "@utils/permission";
import UnderWritingQuestion from "./underwriting-question";
import ZeeRating from "./zee-rating";
import ZorChecklist from "./zor-checklist";
import StandardQuestions from "./standard-questions";
import ZorOverview from "./zor-overview";
import QuoteProposal from "./quote-proposal";
import { VIEW_SOURCE_ADMIN } from "@assets/const/ui";

const { TabPane } = Tabs;

const TAB_OVERVIEW = "TAB_OVERVIEW";
const TAB_FRANCHISEES = "TAB_FRANCHISEES";
const TAB_ACCOUNT = "TAB_ACCOUNT";
const TAB_INSURANCE_REQUIREMENTS = "TAB_INSURANCE_REQUIREMENTS";
const TAB_INSURANCE_BUNDLE = "TAB_INSURANCE_BUNDLE";
const TAB_INTEGRATION = "TAB_INTEGRATION";
const TAB_UNDERWRITING = "TAB_UNDERWRITING";
const TAB_QUOTE_PROPOSAL = "TAB_QUOTE_PROPOSAL";
const TAB_STANDARD_QUESTIONS = "TAB_STANDARD_QUESTIONS";
const TAB_CUSTOM_QUESTIONS = "TAB_CUSTOM_QUESTIONS";
const TAB_ZEE_RATING = "TAB_ZEE_RATING";
const TAB_REAL_TIME_NOTIFICATIONS_POC = "TAB_REAL_TIME_NOTIFICATIONS_POC";
const TAB_ZOR_CHECKLIST = "TAB_ZOR_CHECKLIST";

export default function FranchiseeOverview(props) {
  const { franchisorList, franchisorAdmin, authSetting } = useAppState();

  function handleChangeTab(key) {}

  const title = franchisorAdmin
    ? franchisorAdmin?.personal_infor_business_name
    : "All Franchisees";

  const image = franchisorAdmin ? franchisorAdmin?.personal_infor_logo : null;
  return (
    <div className="admin-franchisees-container">
      <Card title={title} image={image}>
        <Tabs
          type="card"
          defaultActiveKey={TAB_FRANCHISEES}
          onChange={handleChangeTab}
        >
          {franchisorAdmin && (
            <TabPane tab="Overview" key={TAB_OVERVIEW}>
              <ZorOverview requirement={franchisorAdmin} />
            </TabPane>
          )}
          {franchisorAdmin && (
            <TabPane tab="Zor" key={TAB_ACCOUNT}>
              <PersonalInfor viewSource={VIEW_SOURCE_ADMIN} />
            </TabPane>
          )}
          <TabPane tab="Zee's" key={TAB_FRANCHISEES}>
            <ZeeList />
          </TabPane>
          {/* {franchisorAdmin &&
            PERMISSION_TABLE["INTEGRATION_MANAGEMENT"][
              authSetting.userPermission
            ] === ACCESS_FULL && (
              <TabPane tab="POC" key={TAB_REAL_TIME_NOTIFICATIONS_POC}>
                <RealTimeNotifications />
              </TabPane>
            )} */}

          {franchisorAdmin && (
            <TabPane tab="Requirements" key={TAB_INSURANCE_REQUIREMENTS}>
              <InsuranceRequirements />
            </TabPane>
          )}

          {franchisorAdmin && (
            <TabPane tab="Risk Profile" key={TAB_INSURANCE_BUNDLE}>
              <InsuranceBundle franchisorData={franchisorList} />
            </TabPane>
          )}
          {franchisorAdmin && (
            <TabPane tab="Standard Questions" key={TAB_STANDARD_QUESTIONS}>
              <StandardQuestions franchisor={franchisorAdmin} />
            </TabPane>
          )}
          {franchisorAdmin && (
            <TabPane tab="ULP" key={TAB_CUSTOM_QUESTIONS}>
              <UnderWritingQuestion
                isCustom={true}
                customQuestions={franchisorAdmin.custom_questions}
              />
            </TabPane>
          )}
          {franchisorAdmin && (
            <TabPane tab="Quote Proposal" key={TAB_QUOTE_PROPOSAL}>
              <QuoteProposal franchisorData={franchisorAdmin} />
            </TabPane>
          )}
          {franchisorAdmin && (
            <TabPane tab="Underwriting" key={TAB_UNDERWRITING}>
              <UnderWritingQuestion />
            </TabPane>
          )}
          {franchisorAdmin && (
            <TabPane tab="Rating Basis" key={TAB_ZEE_RATING}>
              <ZeeRating />
            </TabPane>
          )}
          {franchisorAdmin &&
            PERMISSION_TABLE["INTEGRATION_MANAGEMENT"][
              authSetting.userPermission
            ] === ACCESS_FULL && (
              <TabPane tab="FMS" key={TAB_INTEGRATION}>
                <Integrations />
              </TabPane>
            )}

          {franchisorAdmin && (
            <TabPane tab="Zor Checklist" key={TAB_ZOR_CHECKLIST}>
              <ZorChecklist />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </div>
  );
}
