import React, { useMemo } from "react";
import AdminUsers from "@containers/admin-board/admin-setting//admin-users";
import AdminCodes from "@containers/admin-board/admin-setting//admin-codes";
import AdminAssignments from "@containers/admin-board/admin-setting//admin-assignments";
import AdminFranchisees from "@containers/admin-board/admin-setting//admin-franchisees";
import AdminIRTemplate from "@containers/admin-board/admin-setting//admin-irtemplate";
import AdminZorCheck from "@containers/admin-board/admin-setting//admin-zorcheck";
import AdminCoverageType from "@containers/admin-board/admin-setting//admin-coverage-type";
import AdminMetaData from "@containers/admin-board/admin-setting/admin-meta-data";
import AdminApiKeys from "./admin-api-keys";
import { Switch, Route } from "react-router-dom";
import "./style.scss";
import FranchisorOverview from "../franchisor-overview";
import { ZOR_VIEW_ARCHIVED } from "@assets/const/ui";

export default function AdminSetting(props) {
  const domainPermission = useMemo(() => {
    switch (window.location.hostname) {
      case "app.rikor.io":
      case "dev.app.rikor.io":
      case "localhost":
        return true;
      default:
        return false;
    }
  }, [window?.location]);

  return (
    <Switch>
      <Route path="/admin/settings/users" component={AdminUsers} />
      <Route path="/admin/settings/coverage-type" component={AdminCoverageType} />
      <Route path="/admin/settings/meta-data" component={AdminMetaData} />
      <Route path="/admin/settings/ncci-codes" component={AdminCodes} />
      {domainPermission && (
        <Route
          path="/admin/settings/assignments"
          component={AdminAssignments}
        />
      )}
      <Route path="/admin/settings/report" component={AdminFranchisees} />
      <Route path="/admin/settings/templates" component={AdminIRTemplate} />
      <Route path="/admin/settings/checklist" component={AdminZorCheck} />
      <Route
        path="/admin/settings/archived-zor"
        render={() => <FranchisorOverview viewMode={ZOR_VIEW_ARCHIVED} />}
      />
      <Route path="/admin/settings/api-keys" component={AdminApiKeys} />
    </Switch>
  );
}
