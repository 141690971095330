import React, { useState, useEffect } from "react";
import { Card, Button, Input, message, Modal, Select } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  BorderlessTableOutlined,
} from "@ant-design/icons";
import { patch, deleteAPI, post } from "@utils/axios";
import "./style.scss";

const { confirm } = Modal;

// Example field-type options
const FIELD_TYPE_OPTIONS = [
  { label: "String", value: "string" },
  { label: "Number", value: "number" },
  { label: "Date", value: "date" },
  { label: "Boolean", value: "boolean" },
];

const MetaType = React.memo(({ tableData, metaType }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [editMode, setEditMode] = useState({});
  const [editedFields, setEditedFields] = useState({});
  const [bundleLabels, setBundleLabels] = useState({});
  const [editedAPINames, setEditedAPINames] = useState({});
  const [originalFields, setOriginalFields] = useState({});
  const [originalLabels, setOriginalLabels] = useState({});
  const [tables, setTables] = useState([]);

  const TABLE_NAME_OPTIONS = [
    { label: "Carrier", value: "Carrier" },
    { label: "Carrier Type", value: "Carrier Type" },
    { label: "Bill Type", value: "Bill Type" },
    { label: "Status", value: "Status" },
  ];

  // On mount / whenever tableData changes, populate local states:
  useEffect(() => {
    const initialFields = {};
    const initialLabels = {};
    const initialAPINames = {};

    tableData.forEach((policy) => {
      initialFields[policy._id] = policy.fields.map((field) => ({
        label: field.displayLabel,
        id: field._id,
        labelType: field.fieldType || "string", // default if none\
        api_name: field.api_name
      }));
      initialLabels[policy._id] = policy.displayLabel;
    });

    setTables(tableData);
    setEditedFields(initialFields);
    setBundleLabels(initialLabels);
  }, [tableData]);

  // Toggle edit mode:
  const toggleEdit = (policyId) => {
    setEditMode((prev) => {
      const isEnteringEditMode = !prev[policyId];
      if (isEnteringEditMode) {
        // Save original states before editing
        setOriginalFields((orig) => ({
          ...orig,
          [policyId]: editedFields[policyId],
        }));
        setOriginalLabels((orig) => ({
          ...orig,
          [policyId]: bundleLabels[policyId],
        }));
      }
      return { ...prev, [policyId]: isEnteringEditMode };
    });
  };

  // Cancel edit → revert to originals:
  const cancelEdit = (policyId) => {
    setEditMode((prev) => ({ ...prev, [policyId]: false }));
    setEditedFields((prev) => ({
      ...prev,
      [policyId]: originalFields[policyId],
    }));
    setBundleLabels((prev) => ({
      ...prev,
      [policyId]: originalLabels[policyId],
    }));
  };

  // Handle changes to the “table name” (bundle label)
  const handleTableNameChange = (policyId, value) => {
    setBundleLabels((prev) => ({
      ...prev,
      [policyId]: value,
    }));
  };

  // Handle changes to the field label (the text)
  const handleFieldChange = (policyId, fieldIndex, newValue) => {
    setEditedFields((prev) => ({
      ...prev,
      [policyId]: prev[policyId].map((field, idx) =>
        idx === fieldIndex ? { ...field, label: newValue } : field
      ),
    }));
  };

  const handleAPINameChange = (policyId, fieldIndex, newValue) => {
    setEditedFields((prev) => ({
      ...prev,
      [policyId]: prev[policyId].map((field, idx) =>
        idx === fieldIndex ? { ...field, api_name: newValue } : field
      ),
    }));
  }
  // Handle changes to the field type (the dropdown)
  const handleLabelTypeChange = (policyId, fieldIndex, newType) => {
    setEditedFields((prev) => ({
      ...prev,
      [policyId]: prev[policyId].map((field, idx) =>
        idx === fieldIndex ? { ...field, labelType: newType } : field
      ),
    }));
  };

  // Example: Save table name to back end
  const handleSaveTableName = async (policyId) => {
    try {
      const token = await getAccessTokenSilently();
      const newTableName = bundleLabels[policyId];
      const fieldsToUpdate = editedFields[policyId]?.map((field) => ({
        fieldId: field.id,
        newLabel: field.label,
        newFieldType: field.labelType,
        newAPIName: field.api_name,
      }));

      const payload = {
        newTableName,
        fields: fieldsToUpdate,
      };

      const response = await patch(
        `admin-board/update-table-name/${policyId}`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        message.success("Table name and fields updated successfully!");
        setEditMode((prev) => ({ ...prev, [policyId]: false }));
      }
    } catch (error) {
      console.error("Error updating table name and fields:", error);
      message.error("Failed to update table name and fields.");
    }
  };


  // Example: Save a single field’s label + labelType to back end
  const handleSave = async (policyId, fieldIndex) => {
    const updatedField = editedFields[policyId][fieldIndex];
    const payload = {
      fieldId: updatedField.id,
      newLabel: updatedField.label,
      newFieldType: updatedField.labelType, // <— also pass the field type
      newAPIName: updatedField.api_name
    };

    try {
      const token = await getAccessTokenSilently();
      const response = await patch(
        `admin-board/update-field/${policyId}`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        message.success("Field updated successfully!");
        // Optionally close edit mode after saving
        setEditMode((prev) => ({ ...prev, [policyId]: false }));
      }
    } catch (error) {
      console.error("Error updating field:", error);
      message.error("Failed to update field.");
    }
  };

  // Show confirm before deleting a field
  const showDeleteConfirm = (policyId, fieldIndex) => {
    confirm({
      title: "Are you sure you want to delete this field?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleRemoveField(policyId, fieldIndex),
    });
  };

  // Remove field from back end
  const handleRemoveField = async (policyId, fieldIndex) => {
    const fieldToRemove = editedFields[policyId][fieldIndex];
    try {
      const token = await getAccessTokenSilently();
      const response = await patch(
        `admin-board/remove-field/${policyId}`,
        { fieldId: fieldToRemove.id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        // Also remove from local states:
        setEditedFields((prev) => ({
          ...prev,
          [policyId]: prev[policyId].filter((_, idx) => idx !== fieldIndex),
        }));
        setOriginalFields((prev) => ({
          ...prev,
          [policyId]: prev[policyId].filter((_, idx) => idx !== fieldIndex),
        }));
        message.success("Field removed successfully!");
      }
    } catch (error) {
      console.error("Error removing field:", error);
      message.error("Failed to remove field.");
    }
  };

  // Add a new field to back end
  const handleAddField = async (policyId) => {
    const newField = {
      id: `temp_${Date.now()}`,
      label: "New Field",
      labelType: "string", // default new field type
    };

    try {
      const token = await getAccessTokenSilently();
      const response = await patch(
        `admin-board/add-field/${policyId}`,
        { newField },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        newField.id = response.data.newFieldId; // real ID
        setEditedFields((prev) => ({
          ...prev,
          [policyId]: [...prev[policyId], newField],
        }));
        message.success("New field added!");
      }
    } catch (error) {
      console.error("Error adding field:", error);
      message.error("Failed to add new field.");
    }
  };

  // Create a new table
  const createTable = async (metaType) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await post(
        "admin-board/create-table",
        { metaType: metaType.toLowerCase() },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 201) {
        const newTable = response.data;
        setTables((prev) => [...prev, newTable]);
        setEditedFields((prev) => ({
          ...prev,
          [newTable._id]: [],
        }));
        setBundleLabels((prev) => ({
          ...prev,
          [newTable._id]: newTable.displayLabel || "New Table",
        }));
        message.success("New table created!");
      }
    } catch (error) {
      console.error("Error creating table:", error);
      message.error("Failed to create table.");
    }
  };

  // Show confirm for deleting an entire table
  const showDeleteTableConfirm = (policyId) => {
    confirm({
      title: "Are you sure you want to delete this table?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDeleteTable(policyId),
    });
  };

  // Actually delete the table
  const handleDeleteTable = async (policyId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await deleteAPI(`admin-board/tables/${policyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setTables(response.data.tables);
      }
      message.success("Table deleted successfully!");
    } catch (error) {
      console.error("Error deleting table:", error);
      message.error("Failed to delete table.");
    }
  };

  return (
    <div className="admin-tab-container">
      <Button
        type="primary"
        className="add-table-button"
        icon={<BorderlessTableOutlined />}
        onClick={() => createTable(metaType)}
      >
        Create Table
      </Button>

      <div className="meta-data-layout policy-type-container">
        {tables.map((policy) => (
          <Card
            key={policy._id}
            title={
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {editMode[policy._id] ? (
                  <>
                     <Select
                      value={bundleLabels[policy._id]}
                      onChange={(value) => handleTableNameChange(policy._id, value)}
                      style={{ width: 200, marginRight: 8 }}
                      options={TABLE_NAME_OPTIONS}
                    />
                  </>
                ) : (
                  bundleLabels[policy._id]
                )}
                <div>
                  {editMode[policy._id] ? (
                    <>
                      <Button
                        icon={<SaveOutlined />}
                        type="primary"
                        onClick={() => handleSaveTableName(policy._id)}
                        style={{ marginRight: "5px" }}
                      >
                        Save
                      </Button>
                      <Button icon={<CloseOutlined />} onClick={() => cancelEdit(policy._id)}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <EditOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "18px",
                          color: "#1478FF",
                          marginRight: "10px",
                        }}
                        onClick={() => toggleEdit(policy._id)}
                      />
                      <DeleteOutlined
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => showDeleteTableConfirm(policy._id)}
                      />
                    </>
                  )}
                </div>
              </div>
            }
            style={{ marginBottom: "20px" }}
          >
            <div>
              <h4 style={{ marginBottom: "10px" }}>Coverage Include:</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  background: "#f5f5f5",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                {editedFields[policy._id]?.map((field, index) => (
                  <div
                    key={field.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "#e8e8e8",
                      padding: "8px",
                      borderRadius: "5px",
                      justifyContent: "space-between",
                      gap: 8
                    }}
                  >
                    {/* Only editable if edit mode is active */}
                    {editMode[policy._id] ? (
                      <>
                        <div style={{ display: "flex", gap: 8, flex: 1 }}>
                          <div>
                            <Input
                              value={field?.label}
                              onChange={(e) => handleFieldChange(policy._id, index, e.target.value)}
                              style={{ flex: 1, height: "5vh" }}
                              placeholder="Field Name"
                            />
                            <Input
                              value={field?.api_name}
                              onChange={(e) => handleAPINameChange(policy._id, index, e.target.value)}
                              style={{ flex: 1, height: "5vh" }}
                              placeholder="API name"
                            />
                          </div>
                          <Select
                            style={{ width: 120, height: "5vh" }}
                            value={field?.labelType}
                            onChange={(newType) => handleLabelTypeChange(policy._id, index, newType)}
                            options={FIELD_TYPE_OPTIONS}
                          />
                        </div>

                        <div>
                          <DeleteOutlined
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => showDeleteConfirm(policy._id, index)}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <span>{field?.label}</span>
                        <span>{field?.api_name}</span>
                        {/* For display, you might show the field.labelType as well, if you like */}
                      </>
                    )}
                  </div>
                ))}
              </div>

              {editMode[policy._id] && (
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => handleAddField(policy._id)}
                  block
                  style={{ marginTop: "10px" }}
                >
                  Add Field
                </Button>
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
});

export default MetaType;
