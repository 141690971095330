import React, { useState, useEffect, useMemo } from "react";
import { Input, Loading } from "@components";
import { Checkbox, Button, notification } from "antd";
import "./style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, put } from "@utils/axios";
import { mapFieldAuto } from "@utils/form-recog";
import {
  buildAnyAuto,
  buildAllOwnedAuto,
  buildHiredAuto,
  buildScheduledAuto,
  buildNonOwnedAuto,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueLT,
  buildValueGT,
  reportAuto,
  getAutoScore,
} from "@utils/build-logic";
import { PERMISSION_TABLE } from "@utils/permission";
import { useAppState } from "@context";
import { isEmpty, min } from "lodash";
import {
  DOC_MAX_LIMIT,
  INSURANCE_STATUS_NON_COMPLIANT,
  STATUS_APPROVE_AUTO,
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING,
} from "@assets/const/status";
import { TYPE_INPUT_DATE } from "@assets/const/ui";
import { validateDate } from "@utils/common";
import { format } from "date-fns";

const DEFAULT_VALUE = {
  automotive_liability_combined_single_limit: 1000000,
  automotive_liability_bodily_injury_pp: 1000,
};

export default function AutoForm(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { docIndex, franchisorData } = props;
  const { authSetting } = useAppState();
  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      (docIndex >= 0 &&
        docIndex ===
          franchiseeData?.finalData?.automotive_liability_form_doc_index) ||
      docIndex === DOC_MAX_LIMIT
    ) {
      if (
        franchiseeData?.finalData?.automotive_liability_form_status ===
          STATUS_SECTION_APPROVE ||
        franchiseeData?.finalData?.automotive_liability_form_status ===
          STATUS_SECTION_PENDING
      ) {
        const finalData = franchiseeData.finalData;

        return {
          automotive_liability_any_auto:
            finalData.automotive_liability_any_auto,
          automotive_liability_all_owned_autos:
            finalData.automotive_liability_all_owned_autos,
          automotive_liability_category_hired_autos:
            finalData.automotive_liability_category_hired_autos,
          automotive_liability_category_scheduled_autos:
            finalData.automotive_liability_category_scheduled_autos,
          automotive_liability_category_non_owned_autos:
            finalData.automotive_liability_category_non_owned_autos,
          automotive_policy_form_additional_insurance:
            finalData.automotive_policy_form_additional_insurance,
          automotive_policy_form_subrogratrion_waived:
            finalData.automotive_policy_form_subrogratrion_waived,
          automotive_liability_combined_single_limit:
            finalData.automotive_liability_combined_single_limit,
          automotive_liability_bodily_injury_pp:
            finalData.automotive_liability_bodily_injury_pp,
          automotive_liability_policy_number:
            finalData.automotive_liability_policy_number,
          automotive_liability_effective_date:
            finalData.automotive_liability_effective_date,
          automotive_liability_expire_date:
            finalData.automotive_liability_expire_date,
        };
      } else {
        return getEmptyForm();
      }
    }

    if (docIndex >= 0) {
      const data = franchiseeData?.recogData[docIndex];

      if (data) {
        return mapFieldAuto(data);
      }
    }

    return getEmptyForm();
  }

  useEffect(() => {
    const { handleUpdateForm } = props;
    handleUpdateForm(formVal);
  }, [formVal]);

  function getEmptyForm() {
    return {
      automotive_liability_any_auto: "",
      automotive_liability_all_owned_autos: "",
      automotive_liability_category_hired_autos: "",
      automotive_liability_category_scheduled_autos: "",
      automotive_liability_category_non_owned_autos: "",
      automotive_policy_form_additional_insurance: "",
      automotive_policy_form_subrogratrion_waived: "",
      automotive_liability_combined_single_limit: 0,
      automotive_liability_bodily_injury_pp: 0,
      automotive_liability_policy_number: "",
      automotive_liability_effective_date: "",
      automotive_liability_expire_date: "",
    };
  }

  const [loading, setLoading] = useState(false);

  async function handleApprove() {
    let { franchiseeData, docIndex } = props;

    if (docIndex >= 0) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        let activities = franchiseeData.activities;
        if (activities) {
          activities.push({
            action: STATUS_APPROVE_AUTO,
            date: new Date(),
          });
        } else {
          activities = [
            {
              action: STATUS_APPROVE_AUTO,
              date: new Date(),
            },
          ];
        }

        let finalData = franchiseeData?.finalData
          ? franchiseeData.finalData
          : {};
        finalData = {
          ...finalData,
          ...formVal,
          automotive_liability_form_status:
            !isEmpty(formVal.automotive_liability_expire_date) &&
            new Date(formVal.automotive_liability_expire_date) < new Date()
              ? STATUS_SECTION_PENDING
              : STATUS_SECTION_APPROVE,
          automotive_liability_form_doc_index: docIndex,
          automotive_liability_effective_date: validateDate(
            formVal.automotive_liability_effective_date
          )
            ? format(
                new Date(formVal.automotive_liability_effective_date),
                "yyyy-MM-dd"
              )
            : null,
          automotive_liability_expire_date: validateDate(
            formVal.automotive_liability_expire_date
          )
            ? format(
                new Date(formVal.automotive_liability_expire_date),
                "yyyy-MM-dd"
              )
            : null,
        };

        const resultIssue = reportAuto(finalData, franchisorData);

        const result = await put(
          `contact/${franchiseeData._id}`,
          {
            finalData: finalData,
            activities: activities,
            autoIssue: resultIssue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await deleteAPI(`notification/delete/${franchiseeData._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { handleUpdateForm } = props;
        handleUpdateForm(result.data.entity);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      handleNotification("Failed", "Need to select one documentation");
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  const coverageScore = useMemo(() => {
    let finalData = props.franchiseeData?.finalData
      ? props.franchiseeData.finalData
      : {};
    finalData = {
      ...finalData,
      ...formVal,
      automotive_liability_form_status:
        !isEmpty(formVal.automotive_liability_expire_date) &&
        new Date(formVal.automotive_liability_expire_date) < new Date()
          ? STATUS_SECTION_PENDING
          : STATUS_SECTION_APPROVE,
      automotive_liability_form_doc_index: docIndex,
      automotive_liability_effective_date: validateDate(
        formVal.automotive_liability_effective_date
      )
        ? formVal.automotive_liability_effective_date
        : null,
      automotive_liability_expire_date: validateDate(
        formVal.automotive_liability_expire_date
      )
        ? formVal.automotive_liability_expire_date
        : null,
    };

    const resultScore = getAutoScore(finalData, franchisorData);
    return resultScore;
  }, [props.franchiseeData, formVal, franchisorData]);

  return (
    <div className="auto-content">
      {coverageScore.visible && (
        <div className="workers-comp-content-score">
          Score: {coverageScore.score} / {coverageScore.total}
        </div>
      )}
      <div className="form-main">
        <div className="panel-left">
          <div className="checkbox-group-container">
            <h5 className="checkbox-group-title">AUTOMOBILE LIABLITY</h5>
            <div className="checkbox-group-content">
              <Checkbox
                checked={formVal.automotive_liability_any_auto}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_any_auto",
                    evt.target.checked
                  )
                }
              >
                Any Auto
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_all_owned_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_all_owned_autos",
                    evt.target.checked
                  )
                }
              >
                All Owned Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_hired_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_hired_autos",
                    evt.target.checked
                  )
                }
              >
                Hired Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_scheduled_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_scheduled_autos",
                    evt.target.checked
                  )
                }
              >
                Scheduled Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_non_owned_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_non_owned_autos",
                    evt.target.checked
                  )
                }
              >
                Non-Owned Autos
              </Checkbox>
            </div>
            {buildAnyAuto(
              formVal.automotive_liability_any_auto,
              franchisorData.automotive_liability_any_auto,
              franchisorData.automotive_liability_required,
              formVal.automotive_liability_category_hired_autos &&
                formVal.automotive_liability_category_non_owned_autos
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}

            {buildAllOwnedAuto(
              formVal.automotive_liability_all_owned_autos,
              franchisorData.automotive_liability_all_owned_autos,
              formVal.automotive_liability_any_auto ||
                (formVal.automotive_liability_category_non_owned_autos &&
                  formVal.automotive_liability_category_hired_autos) ||
                (formVal.automotive_liability_category_scheduled_autos &&
                  formVal.automotive_liability_category_hired_autos) ||
                (formVal.automotive_liability_category_non_owned_autos &&
                  formVal.automotive_liability_category_scheduled_autos),
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            {buildHiredAuto(
              formVal.automotive_liability_category_hired_autos,
              franchisorData.automotive_liability_category_hired_autos,
              formVal.automotive_liability_any_auto,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            {buildScheduledAuto(
              formVal.automotive_liability_category_scheduled_autos,
              franchisorData.automotive_liability_category_scheduled_autos,
              formVal.automotive_liability_any_auto ||
                (formVal.automotive_liability_category_hired_autos &&
                  formVal.automotive_liability_category_non_owned_autos),
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            {buildNonOwnedAuto(
              formVal.automotive_liability_category_non_owned_autos,
              franchisorData.automotive_liability_category_non_owned_autos,
              formVal.automotive_liability_any_auto,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.automotive_policy_form_additional_insurance}
              onChange={(evt) =>
                handleChange(
                  "automotive_policy_form_additional_insurance",
                  evt.target.checked
                )
              }
            >
              Additional Insured
            </Checkbox>
            {buildGeneralLiabilityAdditionalInsurance(
              formVal.automotive_policy_form_additional_insurance,
              franchisorData.automotive_policy_form_additional_insurance,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.automotive_policy_form_subrogratrion_waived}
              onChange={(evt) =>
                handleChange(
                  "automotive_policy_form_subrogratrion_waived",
                  evt.target.checked
                )
              }
            >
              Waiver Of Subrogation
            </Checkbox>
            {buildGeneralLiabilitySubrogrationWaved(
              formVal.automotive_policy_form_subrogratrion_waived,
              franchisorData.automotive_policy_form_subrogratrion_waived,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="alert-container">
            <Input
              title="POLICY NUMBER"
              placeholder="POLICY NUMBER"
              value={formVal.automotive_liability_policy_number}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_policy_number",
                  evt.target.value
                )
              }
            />
            <Input
              title="EFFECTIVE DATE"
              placeholder="EFFECTIVE DATE"
              value={formVal.automotive_liability_effective_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_effective_date",
                  evt.target.value
                )
              }
            />
            {buildEffectDate(
              formVal.automotive_liability_effective_date,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}

            <Input
              title="EXPIRATION DATE"
              placeholder="EXPIRATION DATE"
              value={formVal.automotive_liability_expire_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_expire_date",
                  evt.target.value
                )
              }
            />
            {buildExpireDate(
              formVal.automotive_liability_expire_date,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="COMBINED SINGLE LIMIT"
              subTitle="( Ea occurrence )"
              placeholder="COMBINED SINGLE LIMIT ( Ea occurrence)"
              value={formVal.automotive_liability_combined_single_limit}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_combined_single_limit",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.automotive_liability_combined_single_limit,
              franchisorData.automotive_liability_combined_single_limit,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="Maximum Required Deductible"
              placeholder="Maximum Required Deductible"
              value={formVal.automotive_liability_bodily_injury_pp}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_bodily_injury_pp",
                  evt.target.value
                )
              }
            />
            {buildValueLT(
              formVal.automotive_liability_bodily_injury_pp,
              franchisorData.automotive_liability_bodily_injury_pp,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-footer">
        {PERMISSION_TABLE["FRANCHISEE_DATA_APPROVE"][
          authSetting.userPermission
        ] > 0 && (
          <Button type="primary" size="large" onClick={handleApprove}>
            Approve
          </Button>
        )}
      </div>
      <Loading loading={loading} />
    </div>
  );
}
