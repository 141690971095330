import React, { useState, useEffect } from "react";
import { Breadcrumb } from "antd";
import { useHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import IconRikorEmail from "@assets/images/rikor-logo.svg";
import { Loading } from "@components";
import { post } from "@utils/axios";
import "./style.scss";
import { useAppState } from "@context";
import PageReport from "@pages/report";
import { DO_NOT_CONTACT_OPTION_INACTIVE_ZEE } from "@assets/const/status";

export default function Report(props) {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin } = useAppState();
  const [loading, setLoading] = useState(false);
  const [franchiseeList, setFranchiseeList] = useState(null);

  useEffect(() => {
    if (franchisorAdmin?._id) {
      handleFetchData();
    }
  }, [franchisorAdmin]);

  async function handleFetchData() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      let resultContacts = await post(
        `contacts-list`,
        { requirementId: franchisorAdmin._id },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (resultContacts.data.success) {
        const zeeList = (resultContacts?.data?.data || []).filter(
          (item) => item.doNotContact !== DO_NOT_CONTACT_OPTION_INACTIVE_ZEE
        );
        setFranchiseeList(zeeList);
      }
    } catch (e) {}

    setLoading(false);
  }

  return (
    <div className="zor_view_report">
      <div className="header-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>
            {franchisorAdmin?.personal_infor_business_name}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="zor_view_report-wrapper">
        <PageReport
          requirement={franchisorAdmin}
          franchiseeList={franchiseeList}
          onDetail={(id) => history.push(`/dashboard/franchisees/${id}`)}
        />
        <Loading loading={loading} />
      </div>
    </div>
  );
}
