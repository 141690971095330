import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Button, Input, message, Modal } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppstoreOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { get, patch, post, deleteAPI } from "@utils/axios";
import PolicyType from "./policy-type";
import { COVERAGE } from "@assets/const/admin-board";
import "./style.scss";

const { Sider, Content } = Layout;
const { confirm } = Modal;

export default function AdminCoverageType() {
  const { getAccessTokenSilently } = useAuth0();
  const [tabs, setTabs] = useState([]); // Stores dynamic tabs from backend
  const [selectedKey, setSelectedKey] = useState(null); // Active tab
  const [tableData, setTableData] = useState([]); // Tables for selected tab
  const [loading, setLoading] = useState(false);
  const [newTabName, setNewTabName] = useState("");
  const [editMode, setEditMode] = useState(null); // Track which tab is being edited
  const [editedTabName, setEditedTabName] = useState("");

  useEffect(() => {
    fetchTabs();
  }, []);

  useEffect(() => {
    if (selectedKey) {
      fetchTableData(selectedKey);
    }
  }, [selectedKey]);

  async function fetchTabs() {
    try {
      const response = await get(`admin-board/tabs/${COVERAGE}`); // API route for tabs
      if (response?.data?.length > 0) {
        setTabs(response.data);
        setSelectedKey(response.data[0].name); // Default to first tab
      }
    } catch (error) {
      console.error("Error fetching tabs:", error);
    }
  }

  async function fetchTableData(metaType) {
    setLoading(true);
    metaType = metaType.toLowerCase();
    try {
      const response = await get(`admin-board/tables?metaType=${metaType}`);
      setTableData(response?.data || []);
    } catch (error) {
      console.error("Error fetching table data:", error);
    } finally {
      setLoading(false);
    }
  }

  async function handleAddTab() {
    if (!newTabName.trim()) {
      message.error("Tab name cannot be empty.");
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await post(`admin-board/tabs/${COVERAGE}`, { name: newTabName.toLowerCase() },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        setTabs((prev) => [...prev, { name: newTabName }]);
        setSelectedKey(newTabName);
        setNewTabName("");
        message.success("Tab created successfully!");
      }
    } catch (error) {
      console.error("Error creating tab:", error);
      message.error("Failed to create tab.");
    }
  }

  function confirmDeleteTab(tabName) {
    confirm({
      title: `Are you sure you want to delete '${tabName}'?`,
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => handleDeleteTab(tabName),
    });
  }

  async function handleDeleteTab(tabName) {
    try {
      const token = await getAccessTokenSilently();
      await deleteAPI(`admin-board/tabs/${tabName}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTabs((prev) => prev.filter((tab) => tab.name !== tabName));
      if (selectedKey === tabName) {
        setSelectedKey(tabs.length > 1 ? tabs[0].name : null);
      }
      message.success("Tab deleted successfully!");
    } catch (error) {
      console.error("Error deleting tab:", error);
      message.error("Failed to delete tab.");
    }
  }

  async function handleEditTab(tabName) {
    setEditMode(tabName);
    setEditedTabName(tabName);
  }

  async function handleSaveTab(oldName) {
    console.log("HIT")
    if (!editedTabName.trim()) {
      message.error("Tab name cannot be empty.");
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await patch(`admin-board/tabs/${oldName}`, { newName: editedTabName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setTabs((prev) =>
          prev.map((tab) =>
            tab.name === oldName ? { ...tab, name: editedTabName } : tab
          )
        );
        setSelectedKey(editedTabName);
        setEditMode(null);
        message.success("Tab name updated successfully!");
      }
    } catch (error) {
      console.error("Error updating tab name:", error);
      message.error("Failed to update tab name.");
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="admin-meta-data">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Admin</Breadcrumb.Item>
        <Breadcrumb.Item>
          <p style={{ margin: 0, color: "#1478FF" }}>Coverage Type Manager</p>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Layout style={{ marginTop: 64 }}>
        <Sider width={300} style={{ background: "#f9fafb", padding: "10px" }}>
          <div style={{ marginBottom: "10px" }}>
            <Input
              placeholder="New Tab Name"
              value={newTabName}
              onChange={(e) => setNewTabName(e.target.value)}
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddTab}
              style={{ marginTop: "5px", width: "100%" }}
            >
              Add Tab
            </Button>
          </div>

          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={(e) => setSelectedKey(e.key)}
            className="meta-data-tabs"
          >
            {tabs.map((tab) => (
              <Menu.Item key={tab.name} icon={<AppstoreOutlined />} className="meta-data-tab">
                {editMode === tab.name ? (
                  <Input
                    value={capitalizeFirstLetter(editedTabName)}
                    onChange={(e) => setEditedTabName(e.target.value)}
                    autoFocus
                    style={{ width: "70%", marginRight: "10px" }}
                  />
                ) : (
                  capitalizeFirstLetter(tab.name)
                )}

                {editMode === tab.name ? (
                  <>
                    <SaveOutlined
                      style={{ color: "green", marginRight: "8px" }}
                      onClick={() => handleSaveTab(tab.name)}
                    />
                    <CloseOutlined
                      style={{ color: "red" }}
                      onClick={() => setEditMode(null)}
                    />
                  </>
                ) : (
                  <span className="meta-tab-edit-delete">
                    <EditOutlined
                      style={{ color: "#1478FF", marginRight: "8px" }}
                      onClick={() => handleEditTab(tab.name)}
                    />
                    <DeleteOutlined
                      style={{ color: "red", marginTop: "10px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDeleteTab(tab.name);
                      }}
                    />
                  </span>
                )}

              </Menu.Item>
            ))}
          </Menu>
        </Sider>

        <Layout style={{ padding: "24px", background: "#fff" }}>
          <Content
            style={{ maxWidth: "1200px", paddingTop: "20px" }}
          >
            {selectedKey ? (
              <>
                <h2 style={{ color: "#1478FF" }}>
                  {selectedKey.replace("-", " ").toUpperCase()}
                </h2>
                <PolicyType tableData={tableData} metaType={selectedKey} />
              </>
            ) : (
              <h3 style={{ textAlign: "center" }}>No Tabs Available</h3>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
