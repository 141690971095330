import React from "react";
import { Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import logoRikor from "@assets/images/logo.svg";
import logoMark from "@assets/images/logo-mark.svg";
import logoAuthoritybrands from "@assets/images/logo-authoritybrands.png";
import logoHorsePowerBrands from "@assets/images/logo-horsepowerbrands.svg";
import logoVeteranservicebrands from "@assets/images/logo-veteranservicebrands.png";
import logoHomeFrontsBrands from "@assets/images/logo-homefrontsbrand.png";
import logoFiveStarFranchisingBrand from "@assets/images/logo-five-star-franchising.jpg";
import logoEmpowerbrands from "@assets/images/logo-empowerbrands.png";
import logoThrivemorebrands from "@assets/images/logo-thrivemorebrands.png";
import logoHomerunfranchises from "@assets/images/logo-homerunfranchises.png";
import logoResibrands from "@assets/images/logo-resibrands.png";
import logoFMFlow from "@assets/images/logo-fmflow.png";

import "./style.scss";
import { SendOutlined } from "@ant-design/icons";

const getLogoImage = () => {
  return logoRikor;
};

const getSubLogoImage = () => {
  switch (window.location.hostname) {
    case "app.authoritybrands.rikor.io":
      return logoAuthoritybrands;
    case "app.horsepowerbrands.rikor.io":
      return logoHorsePowerBrands;
    case "app.homefrontbrands.rikor.io":
      return logoHomeFrontsBrands;
    case "app.fivestarfranchising.rikor.io":
      return logoFiveStarFranchisingBrand;
    case "app.httbrands.rikor.io":
      return null;
    case "app.empowerbrands.rikor.io":
      return logoEmpowerbrands;
    case "app.veteranservicebrands.rikor.io":
      return logoVeteranservicebrands;
    case "app.thrivemorebrands.rikor.io":
      return logoThrivemorebrands;
    case "app.homerunfranchises.rikor.io":
      return logoHomerunfranchises;
    case "app.resibrands.rikor.io":
      return logoResibrands;
    case "app.fmflow.rikor.io":
      return logoFMFlow;
    default:
      return null;
  }
};

function SignIn(props) {
  const { loginWithRedirect } = useAuth0();
  const logoImage = getLogoImage();
  const subLogoImage = getSubLogoImage();
  const isSignUp = sessionStorage.getItem("signup");

  function handleSignIn() {
    loginWithRedirect();
  }

  function handleSignInWithEmail() {
    loginWithRedirect({
      authorizationParams: {
        connection: "email"
      }
    });
  }

  function handleSignUp() {
    loginWithRedirect({ screen_hint: "signup" });
  }

  return (
    <div className="signin-page-container">
      <div className="form-container">
        <div className="form-wrapper">
          <div className="btns-container">
            <img src={logoImage} className="logo-image" />
            {subLogoImage && (
              <img src={subLogoImage} className="sub-logo-image" />
            )}
            <div className="btns-container-wrapper">
              <Button
                className="btns-container-signin-code"
                type="primary"
                onClick={handleSignInWithEmail}
                icon={<SendOutlined />}
              >
                Get Code
              </Button>
              <Button
                primary
                ghost
                className="btns-container-signin"
                type="primary"
                onClick={handleSignIn}
              >
                Sign In
              </Button>
            </div>
            {isSignUp && (
              <Button className="btns-container-signup" onClick={handleSignUp}>
                Create A New Account
              </Button>
            )}
            <div className="btns-container-footer">
              <img className="btns-container-footer-img" src={logoMark} />
              <div className="btns-container-footer-label">
                Rikor Insurance Consultancy
              </div>
              <div className="btns-container-footer-link">
                <a
                  className="btns-container-footer-link-policy"
                  href="/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                <a
                  className="btns-container-footer-link-term"
                  href="/terms"
                  target="_blank"
                >
                  Terms & Conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
