export const STATUS_CHANGE = "STATUS_CHANGE";
export const STATUS_EMAIL = "STATUS_EMAIL";
export const STATUS_UPLOAD = "STATUS_UPLOAD";
export const STATUS_APPROVE = "STATUS_APPROVE";
export const STATUS_APPROVE_LIABILITY = "STATUS_APPROVE_LIABILITY";
export const STATUS_APPROVE_AUTO = "STATUS_APPROVE_AUTO";
export const STATUS_APPROVE_WORKERS_COMPENSATION =
  "STATUS_APPROVE_WORKERS_COMPENSATION";
export const STATUS_APPROVE_UMBRELLA = "STATUS_APPROVE_UMBRELLA";
export const STATUS_APPROVE_CRIME = "STATUS_APPROVE_CRIME";
export const STATUS_APPROVE_GARAGE = "STATUS_APPROVE_GARAGE";
export const STATUS_APPROVE_EPLI = "STATUS_APPROVE_EPLI";
export const STATUS_APPROVE_CYBER = "STATUS_APPROVE_CYBER";
export const STATUS_APPROVE_PRO_LIAB = "STATUS_APPROVE_PRO_LIAB";
export const STATUS_APPROVE_POL_LIAB = "STATUS_APPROVE_POL_LIAB";
export const STATUS_APPROVE_IM = "STATUS_APPROVE_IM";
export const STATUS_APPROVE_PRO_INS = "STATUS_APPROVE_PRO_INS";
export const STATUS_APPROVE_ADDITIONAL_INFORMATION =
  "STATUS_APPROVE_ADDITIONAL_INFORMATION";
export const STATUS_EMAIL_ISSUE = "STATUS_EMAIL_ISSUE";
export const STATUS_EMAIL_EXPIRATION = "STATUS_EMAIL_EXPIRATION";
export const STATUS_EMAIL_VERIFICATION_NOT_COMPLIANT =
  "STATUS_EMAIL_VERIFICATION_NOT_COMPLIANT";
export const STATUS_EMAIL_VERIFICATION_PENDING =
  "STATUS_EMAIL_VERIFICATION_PENDING";
export const STATUS_EMAIL_VERIFICATION_NOT_VERIFIED =
  "STATUS_EMAIL_VERIFICATION_NOT_VERIFIED";
export const STATUS_EMAIL_VERIFICATION_EXPIRING =
  "STATUS_EMAIL_VERIFICATION_EXPIRING";
export const STATUS_EMAIL_VERIFICATION_LAPSED =
  "STATUS_EMAIL_VERIFICATION_LAPSED";
export const STATUS_POLICY_STATUS_CHANGE = "STATUS_POLICY_STATUS_CHANGE";
export const STATUS_COMPLIANCE_STATUS_CHANGE =
  "STATUS_COMPLIANCE_STATUS_CHANGE";

export const REQUIREMENT_STATUS_ONBOARD = "onboard";
export const REQUIREMENT_STATUS_DASHBOARD = "dashboard";

export const DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER = "rikorCustomer";
export const DO_NOT_CONTACT_OPTION_INACTIVE_ZEE = "inActiveZee";
export const DO_NOT_CONTACT_OPTION_IS_LEAD = "isLead";
export const DO_NOT_CONTACT_OPTION_NOT_OPEN = "notOpen";
export const DO_NOT_CONTACT_OPTION_NOT_COVERAGE = "notCoverage";
export const DO_NOT_CONTACT_OPTION_CANADA_BUSINESS = "canadianBusiness";
export const DO_NOT_CONTACT_OPTION_CANCEL_POLICY = "cancelPolicy";
export const DO_NOT_CONTACT_OPTION_NONE = null;

export const DO_NOT_CONTACT_LABEL_RIKOR_CUSTOMER = "Rikor Customer";
export const DO_NOT_CONTACT_LABEL_INACTIVE_ZEE = "Inactive Zee";
export const DO_NOT_CONTACT_LABEL_IS_LEAD = "New Lead";
export const DO_NOT_CONTACT_LABEL_NOT_OPEN = "Not Yet Open for Business";
export const DO_NOT_CONTACT_LABEL_NOT_COVERAGE = "Rikor Cannot Obtain Coverage";
export const DO_NOT_CONTACT_LABEL_CANADA_BUSINESS = "Canadian Business";
export const DO_NOT_CONTACT_LABEL_CANCEL_POLICY = "Cancel Policy";
export const DO_NOT_CONTACT_LABEL_ALL = "Non Rikor Customer";

export const DO_NOT_CONTACT_ALL = "all";

export const PERMISSION_TIER_1 = "TIER_1";
export const PERMISSION_TIER_2 = "TIER_2";
export const PERMISSION_TIER_3 = "TIER_3";

export const USER_TYPE_ADMIN = "adminUser";
export const USER_TYPE_FRANCHISOR = "regUser";
export const USER_TYPE_FRANCHISEE = "coiUser";

export const INSURANCE_STATUS_NONE = "Document Missing";
export const INSURANCE_STATUS_COMPLIANT = "Compliant";
export const INSURANCE_STATUS_EXEMPTION = "Exemption";
export const INSURANCE_STATUS_IN_PLACE = "In Place";
export const INSURANCE_STATUS_NON_COMPLIANT = "Non-Compliant";
export const INSURANCE_STATUS_NON_VERIFIED = "Not-Verified";
export const INSURANCE_STATUS_PENDING = "Pending";
export const INSURANCE_STATUS_ALL = "All";

export const STATUS_COMPLETE = "Complete";
export const STATUS_PENDING = "Pending";

export const STATUS_SECTION_APPROVE = "approved";
export const STATUS_SECTION_PENDING = "pending";

export const DOCUMENT_STATUS_ARCHIVED = "archived";
export const DOCUMENT_STATUS_REJECT = "reject";
export const DOCUMENT_STATUS_ACTIVE = "active";

export const STATUS_EXPIRATION_THIRTY = "expire_thirty";
export const STATUS_EXPIRATION_THIRTY_LABEL = "Expiration under 30 days";
export const STATUS_EXPIRATION_SIXTY = "expire_sixty";
export const STATUS_EXPIRATION_SIXTY_LABEL = "Expiration under 60 days";
export const STATUS_EXPIRATION_NINETY = "expire_ninety";
export const STATUS_EXPIRATION_NINETY_LABEL = "Expiration under 90 days";
export const STATUS_EXPIRATION_TODAY = "expire_today";
export const STATUS_EXPIRATION_TODAY_LABEL = "As of Today";
export const STATUS_EXPIRATION_NONE = "expire_none";
export const STATUS_EXPIRATION_NONE_LABEL = "All Franchisees";

export const CATEGORY_DEVELOPMENT = "In Development";
export const CATEGORY_ACTIVE = "Active Franchisees";
export const CATEGORY_TERMINATED = "Terminated Franchisees";
export const CATEGORY_ALL = "CATEGORY_ALL";

export const TIME_PERIOD_ALL = "TIME_PERIOD_ALL";
export const TIME_PERIOD_ONE_MONTH = "TIME_PERIOD_ONE_MONTH";
export const TIME_PERIOD_TWO_MONTH = "TIME_PERIOD_TWO_MONTH";
export const TIME_PERIOD_THREE_MONTH = "TIME_PERIOD_THREE_MONTH";

export const COVERAGE_KEY_LIABILITY = "LIABILITY";
export const COVERAGE_KEY_AUTO = "AUTO";
export const COVERAGE_KEY_WORKERS_COMP = "WORKERS_COMP";
export const COVERAGE_KEY_UMBRELLA = "UMBRELLA";
export const COVERAGE_KEY_ADDITIONAL_INFO = "ADDITIONAL_INFO";
export const COVERAGE_KEY_CRIME = "CRIME";
export const COVERAGE_KEY_GARAGE = "GARAGE";
export const COVERAGE_KEY_EPLI = "EPLI";
export const COVERAGE_KEY_CYBER = "CYBER";
export const COVERAGE_KEY_PROFESSIONAL = "PROFESSIONAL";
export const COVERAGE_KEY_POLLUTION = "POLLUTION";
export const COVERAGE_KEY_INLAND_MARINE = "INLAND_MARINE";
export const COVERAGE_KEY_PRO_INS = "PROPERTY_INSURANCE";
export const COVERAGE_KEY_EXEMPTION = "EXEMPTION";

export const COVERAGE_OPERATOR_AND = "And";
export const COVERAGE_OPERATOR_OR = "Or";

export const DOC_MAX_LIMIT = 9999;

export const EXPIRATION_OPTION = [
  {
    value: STATUS_EXPIRATION_THIRTY,
    label: STATUS_EXPIRATION_THIRTY_LABEL
  },
  {
    value: STATUS_EXPIRATION_SIXTY,
    label: STATUS_EXPIRATION_SIXTY_LABEL
  },
  {
    value: STATUS_EXPIRATION_NINETY,
    label: STATUS_EXPIRATION_NINETY_LABEL
  },
  {
    value: STATUS_EXPIRATION_TODAY,
    label: STATUS_EXPIRATION_TODAY_LABEL
  },
  {
    value: STATUS_EXPIRATION_NONE,
    label: STATUS_EXPIRATION_NONE_LABEL
  }
];

export const CATEGORY_OPTION = [
  {
    value: CATEGORY_DEVELOPMENT,
    label: "In Development"
  },
  {
    value: CATEGORY_ACTIVE,
    label: "Active Franchisees"
  },
  {
    value: CATEGORY_TERMINATED,
    label: "Terminated Franchisees"
  },
  {
    value: CATEGORY_ALL,
    label: "All Franchisees"
  }
];

export const DO_NOT_CONTACT_OPTION = [
  {
    value: DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
    label: DO_NOT_CONTACT_LABEL_RIKOR_CUSTOMER
  },
  {
    value: DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
    label: DO_NOT_CONTACT_LABEL_INACTIVE_ZEE
  },
  {
    value: DO_NOT_CONTACT_OPTION_IS_LEAD,
    label: DO_NOT_CONTACT_LABEL_IS_LEAD
  },
  {
    value: DO_NOT_CONTACT_OPTION_NOT_OPEN,
    label: DO_NOT_CONTACT_LABEL_NOT_OPEN
  },
  {
    value: DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
    label: DO_NOT_CONTACT_LABEL_NOT_COVERAGE
  },
  {
    value: DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
    label: DO_NOT_CONTACT_LABEL_CANADA_BUSINESS
  },
  {
    value: DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
    label: DO_NOT_CONTACT_LABEL_CANCEL_POLICY
  },
  {
    value: DO_NOT_CONTACT_ALL,
    label: DO_NOT_CONTACT_LABEL_ALL
  }
];

export const TIME_PERIOD_OPTION = [
  { value: TIME_PERIOD_ALL, label: "All Franchisees" },
  { value: TIME_PERIOD_ONE_MONTH, label: "30 Days" },
  { value: TIME_PERIOD_TWO_MONTH, label: "2 Months" },
  { value: TIME_PERIOD_THREE_MONTH, label: "3 Months" }
];
