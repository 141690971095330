import { Button, Switch } from "antd";
import "./style.scss";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { notify } from "@utils/common";
import _ from "lodash";
import { put } from "@utils/axios";

const defaultStandardQuestions = [
  {
    key: "buildOut",
    question: "Do you have a build-out?",
    enabled: true
  },
  {
    key: "location",
    question:
      "Do you need to insure equipment, stock, inventory, furniture at a physical location?",
    enabled: true
  },
  {
    key: "driversAndVehicles",
    question: "Do you have vehicles and drivers you'd like to add?",
    enabled: true
  }
];

export default function StandardQuestions({ franchisor }) {
  const [questions, setQuestions] = useState(
    franchisor.standard_questions || defaultStandardQuestions
  );
  const { getAccessTokenSilently } = useAuth0();
  const [saving, setSaving] = useState(false);

  function handleToggleQuestion(q, checked) {
    const foundIndex = questions.findIndex(
      (question) => q.key === question.key
    );
    const copy = _.cloneDeep(questions);
    if (foundIndex > -1) {
      copy[foundIndex].enabled = checked;
    }
    setQuestions(copy);
  }

  async function handleSave() {
    const token = await getAccessTokenSilently();
    try {
      setSaving(true);
      await put(
        `requirement/${franchisor._id}`,
        { standard_questions: questions },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      notify("Success", "Successfully saved standard questions.", "success");
    } catch (err) {
      notify("Error", "Failed to save standard questions.");
    } finally {
      setSaving(false);
    }
  }

  return (
    <div>
      {questions.map((q) => (
        <div className="question-container">
          <h3>{q.question}</h3>
          <Switch
            checked={q.enabled || false}
            onChange={(checked) => handleToggleQuestion(q, checked)}
          />
        </div>
      ))}
      <div>
        <Button
          type="primary"
          className="save-button"
          disabled={saving}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
