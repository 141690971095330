import { useState, useEffect } from "react";
import _ from 'lodash';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Table, Input, Select, DatePicker, Space, message, Modal } from "antd";
import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { addYears, parseISO, format } from "date-fns";
import dayjs from "dayjs";
import { post, get, put, deleteAPI } from "@utils/axios";
import { formatToMonthDayYear } from "@utils/date-formatter";

export default function Ledger({
  ledgerData,
  setLedgerData,
  requirementId,
  setSelectedQuote,
  selectedQuote,
  handleSelectPolicy,
  backendData
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false); // API request state

  // Fetch all ledgers on mount
  useEffect(() => {
    const fetchLedgers = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await get(`quote-proposal/get-ledgers/${requirementId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLedgerData(response.data);
      } catch (error) {
        message.error("Error fetching quotes");
        console.error("Fetch quotes failed:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLedgers();
  }, []);

  // Generates a formatted name for the ledger based on selected fields
  const generateLedgerName = (ledger) => {
    return `${ledger.coverage.join(", ")} - ${format(parseISO(ledger.effective), "MM/dd/yyyy")} - $${ledger.premium || "0"} - ${ledger.carrier}`;
  };

  // Updates the ledger data when an input field is changed
  const handleInputChange = (key, field, value) => {
    setLedgerData((prevData) =>
      prevData.map((item) => (item._id === key ? { ...item, [field]: value } : item))
    );
  };

  // Sends new ledger data to backend and updates UI
  const handleAddLedger = async () => {
    const newLedger = {
      name: "", // Will be generated after saving
      coverage: [], // Default selection
      effective: format(new Date(), "yyyy-MM-dd"),
      expiration: format(addYears(new Date(), 1), "yyyy-MM-dd"),
      premium: 0,
      agent: user?.nickname || "Unknown",
      carrier: "Unknown",
      status: "Not in Market",
      requirementId, // Ensure the requirementId is included
    };

    newLedger.name = generateLedgerName(newLedger); // Generate name before saving

    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response = await post("quote-proposal/create-ledger", newLedger, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        const res = response.data.ledger;
        setLedgerData((prevData) => [...prevData, { ...res, key: res._id }]);
        message.success("Quote created successfully");
      }
    } catch (error) {
      message.error("Error creating Quote");
      console.error("Quote creation failed:", error);
    } finally {
      setLoading(false);
    }
  };

  // Updates a ledger in the backend
  const handleSave = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();

      // Send update request for each ledger
      await Promise.all(
        ledgerData.map(async (ledger) => {
          const updatedLedger = { ...ledger, name: generateLedgerName(ledger) };
          return put(`quote-proposal/upate-ledger/${ledger._id}`, updatedLedger, {
            headers: { Authorization: `Bearer ${token}` },
          });
        })
      );

      message.success("All quotes updated successfully");
      setIsEditing(false);
    } catch (error) {
      message.error("Error updating quotes");
      console.error("Quote update failed:", error);
    } finally {
      setLoading(false);
    }
  };

  // Deletes a ledger from the backend
  const handleDelete = async (key) => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await deleteAPI(`quote-proposal/delete-ledger/${key}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Remove the deleted ledger from `ledgerData`
      setLedgerData((prevData) => prevData.filter((item) => item._id !== key));

      // Remove the corresponding quote from `selectedQuote`
      setSelectedQuote((prev) => prev.filter((quote) => quote._id !== key));

      message.success("Quote deleted successfully");
    } catch (error) {
      message.error("Error deleting quote");
      console.error("Quote deletion failed:", error);
    } finally {
      setLoading(false);
    }
  };// Import Modal for confirmation dialog

const handleDeleteWithConfirmation = (key) => {
  Modal.confirm({
    title: "Are you sure you want to delete this quote?",
    content: "This action cannot be undone.",
    okText: "Yes, Delete",
    okType: "danger",
    cancelText: "Cancel",
    onOk: () => handleDelete(key), // Call delete function if confirmed
  });
};

const columns = [
  {
    title: "Coverage",
    dataIndex: "coverage",
    key: "coverage",
    render: (text, record) =>
      isEditing ? (
        <Select
          mode="multiple"
          value={Array.isArray(record.coverage) ? record.coverage : []}
          style={{ width: 200 }}
          onChange={(value) => handleInputChange(record._id, "coverage", value)}
        >
          {Object.keys(backendData).map((key) => (
            <Select.Option key={key} value={key}>
              {key}
            </Select.Option>
          ))}
        </Select>
      ) : Array.isArray(record.coverage) ? (
        record.coverage.join(", ")
      ) : (
        record.coverage
      ),
  },
  {
    title: "Effective",
    dataIndex: "effective",
    key: "effective",
    render: (text, record) =>
      isEditing ? (
        <DatePicker
          value={record.effective ? dayjs(record.effective) : null}
          onChange={(date) =>
            handleInputChange(record._id, "effective", date ? dayjs(date).format("YYYY-MM-DD") : "")
          }
        />
      ) : text && !isNaN(new Date(text).getTime()) ? (
        formatToMonthDayYear(text)
      ) : (
        "N/A"
      ),
  },
  {
    title: "Expiration",
    dataIndex: "expiration",
    key: "expiration",
    render: (text, record) =>
      isEditing ? (
        <DatePicker
          value={record.expiration ? dayjs(record.expiration) : null}
          onChange={(date) =>
            handleInputChange(record._id, "expiration", date ? dayjs(date).format("YYYY-MM-DD") : "")
          }
        />
      ) : text && !isNaN(new Date(text).getTime()) ? (
        formatToMonthDayYear(text)
      ) : (
        "N/A"
      ),
  },
  {
    title: "Premium",
    dataIndex: "premium",
    key: "premium",
    render: (text, record) =>
      isEditing ? (
        <Input
          type="number"
          value={text}
          onChange={(e) => handleInputChange(record._id, "premium", Number(e.target.value))}
        />
      ) : (
        `$${text}`
      ),
  },
  {
    title: "Agent",
    dataIndex: "agent",
    key: "agent",
    render: (text, record) => text
  },
  {
    title: "Carrier",
    dataIndex: "carrier",
    key: "carrier",
    render: (text, record) =>
      isEditing ? (
        <Input value={text} onChange={(e) => handleInputChange(record._id, "carrier", e.target.value)} />
      ) : (
        text
      ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record) =>
      isEditing ? (
        <Select
          value={text}
          style={{ width: 140 }}
          onChange={(value) => handleInputChange(record._id, "status", value)}
        >
          <Select.Option value="Not in Market">Not in Market</Select.Option>
          <Select.Option value="Active">Active</Select.Option>
          <Select.Option value="Expired">Expired</Select.Option>
        </Select>
      ) : (
        <Button type="dashed">{text}</Button>
      ),
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <Button
        type="text"
        danger
        icon={<DeleteOutlined />}
        onClick={() => handleDeleteWithConfirmation(record._id)}
      />
    ),
  },
];


  console.log("selectedQuote", selectedQuote);
  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Button icon={<PlusOutlined />} onClick={handleAddLedger} loading={loading}>
          Create New Quote
        </Button>
        {ledgerData.length !== 0 && (
          <Button
            style={{ float: "right" }}
            onClick={isEditing ? handleSave : () => setIsEditing(true)}
            loading={loading}
          >
            {isEditing ? "Save" : <EditOutlined />}
          </Button>
        )}
      </Space>

      <Table
        columns={columns}
        dataSource={ledgerData}
        pagination={false}
        rowKey="_id"
        onRow={(record) => ({
          onClick: () => {
            if (setSelectedQuote && !isEditing) {
              setSelectedQuote((prev) => {
                // Ensure prev is always an array
                const currentArray = Array.isArray(prev) ? prev : Object.values(prev);

                // Check if the record is already selected
                const isSelected = currentArray.some(item => item._id === record._id);

                if (isSelected) {
                  // Remove the quote if already selected
                  return currentArray.filter(item => item._id !== record._id);
                } else {
                  // Add the quote and update the table
                  const updatedQuotes = [...currentArray, record];

                  // Extract new index for triggering policy selection
                  const newIndex = updatedQuotes.length - 1;

                  // Call handleSelectPolicy to ensure table updates immediately
                  handleSelectPolicy(newIndex, record.coverage.map(c => c.toLowerCase()));

                  return updatedQuotes;
                }
              });
            }
          },
        style: {
          cursor: 'pointer',
          backgroundColor: selectedQuote.some(item => item._id === record._id) ? '#F8FDFE' : 'transparent', // Change background color if selected
          }
        })}
      />
    </div>
  );
}
