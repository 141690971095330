import React, { useEffect } from "react";
import { notification } from "antd";
import { useAppState } from "../../context";
import { userRole } from "../../assets/const/auth";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Main from "../../containers/main";
import {
  PERMISSION_TIER_3,
  REQUIREMENT_STATUS_DASHBOARD,
  REQUIREMENT_STATUS_ONBOARD,
  USER_TYPE_ADMIN,
  USER_TYPE_FRANCHISOR
} from "../../assets/const/status";

let AUTH_API_CALL = false;

export default function AuthContainer(WrapComponent) {
  return function AuthWrapper(props) {
    const {
      user,
      isAuthenticated,
      isLoading,
      logout,
      getAccessTokenSilently,
      loginWithRedirect
    } = useAuth0();
    const { authSetting, setAuthSetting, franchisorAdmin, setFranchisorAdmin } =
      useAppState();

    const location = useLocation();

    const hasAccessToken = window.location?.hash?.includes("access_token");

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      if (params.get("signup")) {
        sessionStorage.setItem("signup", true);
      }
      if (params.get("requirementId")) {
        sessionStorage.setItem("requirementId", params.get("requirementId"));
      }
    }, [location]);

    function getPermission(accessDomain) {
      if (accessDomain) {
        if (accessDomain == "") {
          return true;
        }

        // Start - Dev Access
        if (
          window.location.hostname === "localhost" ||
          window.location.hostname === "dev.app.rikor.io"
        ) {
          return true;
        }
        // End - Dev Access

        const domainList = accessDomain.split(",");
        let matchedFlag = false;
        for (let index = 0; index < domainList.length; index++) {
          if (domainList[index] == window.location.hostname) {
            matchedFlag = true;
          }
        }
        if (!matchedFlag) {
          return false;
        }
      }

      return true;
    }

    function handleNotification(title, description) {
      notification["error"]({
        message: title,
        description: description,
        duration: 20
      });
    }

    if (isLoading) {
      return <Main />;
    }

    if (!isAuthenticated) {
      if (
        location.pathname !== "/sign-in" &&
        location.pathname !== "/sign-up"
      ) {
        if (location.pathname !== "/") {
          sessionStorage.setItem(
            "redirect_url",
            location.pathname + location.search
          );
        }
      }
      if (hasAccessToken) {
        loginWithRedirect();
      } else {
        if (
          location.pathname !== "/sign-in" &&
          location.pathname !== "/sign-up"
        ) {
          handleNotification(
            "User not found.",
            "Use the email associated with rms. Contact your Consultant if the the problem persists."
          );
          logout({
            logoutParams: { returnTo: `${window.location.origin}/sign-in` }
          });
          // return <Redirect to="/sign-in" />;
        }
      }
    }

    if (isAuthenticated) {
      if (authSetting.isAuth) {
        if (!getPermission(authSetting.user.access_domain)) {
          return <Redirect to="/404" />;
        }
        if (
          location.pathname === "/" ||
          location.pathname === "/sign-in" ||
          location.pathname === "/sign-up"
        ) {
          if (authSetting.userRole === userRole.ROLE_FRANCHISEE) {
            return (
              <Redirect
                to={sessionStorage.getItem("redirect_url") || "/board/account"}
              />
            );
          }

          if (franchisorAdmin?.status) {
            return <Redirect to="/dashboard/overview" />;
          } else {
            return <Redirect to="/onboarding" />;
          }
        }

        if (location.pathname && location.pathname.includes("/admin/")) {
          if (authSetting.userRole === userRole.ROLE_FRANCHISOR) {
            if (franchisorAdmin?.status) {
              return <Redirect to="/dashboard/overview" />;
            } else {
              return <Redirect to="/onboarding" />;
            }
          }
          if (authSetting.userRole === userRole.ROLE_FRANCHISEE) {
            return (
              <Redirect
                to={sessionStorage.getItem("redirect_url") || "/board/account"}
              />
            );
          }
        } else if (
          location.pathname &&
          location.pathname.includes("/dashboard/")
        ) {
          if (authSetting.userRole === userRole.ROLE_FRANCHISEE) {
            return (
              <Redirect
                to={sessionStorage.getItem("redirect_url") || "/board/account"}
              />
            );
          }

          if (authSetting.userRole === userRole.ROLE_ADMIN) {
            return <Redirect to="/admin/overview" />;
          }
        } else if (
          location.pathname &&
          location.pathname.includes("/onboarding")
        ) {
          if (authSetting.userRole === userRole.ROLE_FRANCHISOR) {
            if (franchisorAdmin?.status) {
              return <Redirect to="/dashboard/overview" />;
            }
          }

          if (authSetting.userRole === userRole.ROLE_ADMIN) {
            return <Redirect to="/admin/overview" />;
          }
        }
      } else {
        getAccessTokenSilently().then((token) => {
          if (!AUTH_API_CALL) {
            AUTH_API_CALL = true;
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/auth`,
                {
                  ...user,
                  requirementId: sessionStorage.getItem("requirementId")
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                }
              )
              .then((res) => {
                if (res.data.success) {
                  setAuthSetting({
                    isAuth: true,
                    token: "",
                    refreshToken: "",
                    user: res.data.user,
                    userRole:
                      res.data.user.type === USER_TYPE_ADMIN
                        ? userRole.ROLE_ADMIN
                        : res.data.user.type === USER_TYPE_FRANCHISOR
                        ? userRole.ROLE_FRANCHISOR
                        : userRole.ROLE_FRANCHISEE,
                    userPermission:
                      res.data.user.permission || PERMISSION_TIER_3
                  });
                  if (res.data.requirement) {
                    setFranchisorAdmin({ ...res.data.requirement });
                  }
                }
              })
              .catch((error) => {
                handleNotification(
                  "User not found.",
                  "Use the email associated with rms. Contact your Consultant if the the problem persists."
                );
                logout({
                  logoutParams: {
                    returnTo: `${window.location.origin}/sign-in`
                  }
                });
              });
          }
        });
        return <Main />;
      }
    }

    return <WrapComponent {...props} />;
  };
}
